import { customElement } from 'lit/decorators/custom-element.js';
import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import '@warp-ds/elements/components/card';
import '@warp-ds/elements/components/badge';
import '@warp-ds/icons/elements/link-external-16';
import { i18n } from '@lingui/core';
import { formatCurrency } from '@borealis/number-format';
import ToriOikotie from './tori_oikotie.js';
import '@warp-ds/icons/elements/pin-marker-16';
import '@warp-ds/icons/elements/info-16';
import '@warp-ds/elements/components/attention';
import '@warp-ds/elements/components/button';
import '@warp-ds/icons/elements/chevron-right-16';
import pulse from '@finn-no/pulse-sdk';
// import { attention } from '@warp-ds/css/component-classes';
import { OikotieCard, OikotieData } from '../../server/types.js';

@customElement('oikotie-section')
export class OikotieSection extends WarpElement {
	static styles = [
		...WarpElement.styles,
		css`
			.custom-label-style {
				background: linear-gradient(
					transparent,
					rgba(0, 0, 0, 0.04935) 13.71%,
					/* ... (rest of the gradient styles) */ rgba(0, 0, 0, 0.78731) 89.73%,
					rgba(0, 0, 0, 0.8)
				);
				width: 100%;
			}
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			@css-placeholder-RTuMD1;
		`,
	];

	url!: string;
	oikotieData!: OikotieData;
	observer!: IntersectionObserver;
	isTracked!: boolean;

	static properties = {
		url: { type: String },
		oikotieData: { type: Object, default: {} },
	};

	async connectedCallback() {
		super.connectedCallback();

		this.observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					if (this.isTracked) return;
					if (this.oikotieData?.cards?.length > 0) {
						this.isTracked = true;
						pulse.trackEvent({
							type: 'View',
							name: 'Oikotie teaser',
							intent: 'Show',
							object: {
								type: 'UIElement',
								elementType: 'Section',
							},
							vertical: { name: 'common_pages' },
						});
					}
				}
			},
			{
				root: null, // Uses the viewport
				threshold: 0.5, // Trigger when at least 50% of the element is visible
			},
		);

		if (this.observer instanceof IntersectionObserver) {
			this.observer.observe(this);
		} else {
			console.error('Observer is not correctly initialized:', this.observer);
		}

		/*const attentionComponent = this.shadowRoot?.getElementById('attention-component')
		attentionComponent?.insertAdjacentHTML(
			'afterbegin',
			`<w-attention id="popoverElement" placement="top-end" popover>
							<w-button
								id="popoverTarget"
								quiet
								variant="utility"
								slot="target"
								class="relative"
							>
								<w-icon-info-16></w-icon-info-16>
							</w-button>
							<div slot="message">
								<h4>${i18n.t({
				id: 'frontpage.oikotie.card.popover.title',
				message: 'Explore apartments for sale near you',
			})}
								</h4>
								<p>${i18n.t({
				id: 'frontpage.oikotie.card.popover.body',
				message: 'Browse available listings from Oikotie directly on Tori and find your next home with ease.',
			})}</p>
							</div>
						</w-attention>`
		)
		const popoverTarget = this.shadowRoot?.getElementById('popoverTarget')
		const popoverElement = this.shadowRoot?.getElementById('popoverElement')


		popoverTarget?.addEventListener('click', () => {
			if (popoverElement) {
				setTimeout(() => {
					popoverElement.show = !popoverElement.show;
				}, 50);
			}
		});*/
	}

	trackAdClick(position: string) {
		pulse.trackEvent({
			type: 'Click',
			name: 'Oikotie teaser',
			intent: 'Open',
			object: {
				type: 'UIElement',
				elementType: 'Ad',
				position: position,
			},
			vertical: { name: 'common_pages' },
		});
	}
	trackSeeMoreClick() {
		pulse.trackEvent({
			type: 'Click',
			name: 'Oikotie teaser',
			intent: 'Open',
			object: {
				type: 'UIElement',
				elementType: 'See more',
			},
			vertical: { name: 'common_pages' },
		});
	}

	render() {
		if (this.oikotieData?.cards?.length > 0) {
			return html`
				<section class="mt-24 mb-16">
					<div class="pb-16 flex justify-between">
						${ToriOikotie()}
						<div id="attention-component"></div>
					</div>
					<div class="flex flex-row">
						<h2 class="text-s mr-8">
							${i18n.t({
								id: 'frontpage.oikotie.section.title',
								message: 'Apartment for sale',
							})}
						</h2>
						<w-icon-pin-marker-16 class="mr-4"></w-icon-pin-marker-16>
						<p class="text-s">${this.oikotieData?.search_location}</p>
					</div>
					<div class="w-screen overflow-x-visible translate-x-[-16px]">
						<div class="grow bg-transparent overflow-x-auto my-8">
							<section class="grid grid-rows-1 auto-cols-[minmax(220px,_2fr)] grid-flow-col gap-16 mb-8 ml-16">
								${this.oikotieData?.cards?.map(
									(card: OikotieCard, index: number) => html`
										<w-card class="z-10">
											${card.image
												? html` <div
														class="aspect-4/3 overflow-hidden border rounded-t-8 s-bg hover:s-bg-hover active:s-bg-active"
													>
														<img
															class="w-full h-full object-center object-cover m-auto"
															src="${card.image}"
															alt="${i18n.t({
																id: 'frontpage.recommendations.images.alt.text',
																message: 'Photo from ad',
															})}"
														/>
														<span>
															<div
																class="absolute bottom-0 font-bold s-text-inverted-static pt-[88px] pb-10 pl-10 pr-16 custom-label-style"
															>
																${formatCurrency(card.price, 'fi', 'EUR')}
															</div>
														</span>
													</div>`
												: ''}
											<h2 class="text-s s-text-subtle font-normal mx-16 mt-8">
												${card.district && html`<span>${card.district}, </span>`}
												<span>${card.city}</span>
											</h2>
											<h2 class="mx-16 mt-6 mb-14 text-body font-normal">
												<a
													class="s-text hover:no-underline flex flex-row space-x-8 items-center truncate"
													href="${card.url}"
													target="_blank"
													@click="${() => this.trackAdClick((++index).toString())}"
												>
													<span>${card.size.toString().replace('.', ',')} m²</span>
													${card.property_type &&
													html`<span class="flex rounded-full h-[3px] w-[3px] bg-[--w-gray-600]"></span>
														<span> ${card.property_type} </span>`}
													<span class="absolute inset-0" aria-hidden="true"></span>
												</a>
											</h2>
											<w-badge variant="info" position="top-left">
												<w-icon-link-external-16 class="flex pr-4"></w-icon-link-external-16>
												${i18n.t({
													id: 'frontpage.oikotie.card.badge',
													message: 'Opens in Oikotie',
												})}
											</w-badge>
										</w-card>
									`,
								)}
							</section>
						</div>
					</div>
					${this.oikotieData?.see_more_link &&
					html`<div class="flex flex-col">
						<a
							class="flex items-center text-xs font-bold mt-18 py-8 no-underline focus:no-underline focus:focusable w-[55%]"
							href="${this.oikotieData?.see_more_link}"
							@click="${() => this.trackSeeMoreClick()}"
							target="_blank"
							>${i18n.t({
								id: 'frontpage.oikotie.section.button',
								message: 'See more apartments',
							})} <w-icon-chevron-right-16 class="pl-8 pt-2 primary"></w-icon-chevron-right-16
						></a>
					</div>`}
				</section>
			`;
		}
	}
}
