import { i18n } from '@lingui/core';
import { unsafeStatic, html } from "lit/static-html.js";

export default () => html`<svg width="171" height="24" viewBox="0 0 171 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<title>${unsafeStatic(i18n.t({
	id: 'frontpage.oikotie.card.logos',
	message: 'Tori heart Oikotie',
}))}</title>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.3748 2.89304C49.3584 4.46928 48.0676 5.73579 46.4872 5.72645C44.9067 5.7171 43.6311 4.43541 43.6334 2.85909C43.6357 1.28278 44.9151 0.00481592 46.4956 8.40513e-05C47.2647 -0.00580314 48.0037 0.297794 48.5456 0.842239C49.0875 1.38668 49.3864 2.126 49.3748 2.89304ZM22.2104 7.74374C17.4074 7.74374 13.865 11.2181 13.865 15.8718C13.865 20.5255 17.4074 23.9999 22.2104 23.9999C27.0294 23.9999 30.5877 20.5255 30.5877 15.8718C30.5877 11.2181 27.0294 7.74374 22.2104 7.74374V7.74374ZM22.2104 11.6165C24.556 11.6165 26.3272 13.3696 26.3272 15.8718C26.3272 18.374 24.556 20.1271 22.2104 20.1271C19.8647 20.1271 18.1095 18.374 18.1095 15.8718C18.1095 13.3697 19.8647 11.6165 22.2104 11.6165V11.6165ZM40.4777 7.74372C38.5789 7.74372 37.0949 8.39717 36.1215 9.84745V8.15811H32.0844V23.6014H36.2492V15.1865C36.2492 12.8915 38.0447 11.8556 40.1875 11.8556C41.9785 11.8556 42.6478 12.2062 42.6478 12.2062V8.15811C41.9597 7.87597 41.2217 7.73504 40.4777 7.74373V7.74372ZM48.6018 8.15812H44.4371V23.6014H48.6018V8.15812ZM12.7495 8.15813H7.14865V3.99847H2.98392V8.15813H0V11.8556H2.98392V17.8321C2.98392 21.8324 4.9466 23.9999 8.77625 23.9999C10.652 24.01 12.4812 23.4174 13.993 22.3098L12.246 19.1952C11.4003 19.7052 10.4517 20.1271 9.46239 20.1271C8.16989 20.1271 7.14866 19.4259 7.14866 17.4974V11.8556H12.7495L12.7495 8.15813Z" fill="#F94F55"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M69.3555 18.5313C69.7413 18.5313 69.9592 18.2138 70.5806 17.5923C71.3573 16.8154 72.1061 16.0914 72.6499 15.5476C74.2553 13.9419 74.8865 13.3326 75.3345 12.8572C76.525 11.5939 76.6794 9.83862 75.8721 8.5061C74.8542 6.8262 73.3362 6.53125 72.5199 6.53125C71.7037 6.53125 71.12 6.8262 70.0891 7.58547L69.3743 8.21629L68.679 7.58547C67.5607 6.68418 66.7509 6.53125 66.231 6.53125C65.7976 6.53125 63.9875 6.53151 62.821 8.5061C61.9699 9.94687 62.4146 11.7217 63.499 12.8572C63.6932 13.0606 64.4934 13.8511 65.882 15.2745C66.441 15.8476 67.2321 16.6357 67.8145 17.2341C68.1445 17.5733 68.9697 18.5313 69.3555 18.5313Z" stroke="#F95157" stroke-width="1.5"/>
<g clip-path="url(#clip0_512_19807)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M160.041 1.54883H89.375V23.4923H160.041H160.042L170.724 12.522V12.5204L160.042 1.5498V1.54883H160.041Z" fill="#202020"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M109.144 17.4089H111.214V7.63477H109.144V17.4089Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.739 17.4254L115.399 13.9955L115.376 14.0195L115.353 13.9966V17.4121H113.284V7.63793H115.353V11.0357L118.682 7.61719H121.61L116.862 12.4919L121.666 17.4254H118.739Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.283 9.75888H136.539V17.4069H134.469V9.75888H131.683V7.63281H139.283V9.75888Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M141.354 17.4089H143.423V7.63477H141.354V17.4089Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M152.035 9.75888H147.586V11.3828H152.035V13.5089H147.586V15.2818H152.035V17.4069H145.493V7.63281H152.035V9.75888Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.38 9.82789C100.932 9.82789 99.7586 11.0333 99.7586 12.5205C99.7586 14.0076 100.932 15.213 102.38 15.213C103.828 15.213 105.002 14.0076 105.002 12.5205C105.002 11.0333 103.828 9.82789 102.38 9.82789ZM102.38 17.431C99.7395 17.431 97.5986 15.2324 97.5986 12.5202C97.5986 9.80798 99.7395 7.60938 102.38 7.60938C105.021 7.60938 107.162 9.80798 107.162 12.5202C107.162 15.2324 105.021 17.431 102.38 17.431Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M126.186 9.82789C124.738 9.82789 123.564 11.0333 123.564 12.5205C123.564 14.0076 124.738 15.213 126.186 15.213C127.634 15.213 128.808 14.0076 128.808 12.5205C128.808 11.0333 127.634 9.82789 126.186 9.82789ZM126.186 17.431C123.545 17.431 121.404 15.2324 121.404 12.5202C121.404 9.80798 123.545 7.60938 126.186 7.60938C128.827 7.60938 130.968 9.80798 130.968 12.5202C130.968 15.2324 128.827 17.431 126.186 17.431Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_512_19807">
<rect width="81.3488" height="22" fill="white" transform="translate(89.375 1.53125)"/>
</clipPath>
</defs>
</svg>`;
